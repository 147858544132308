html {
  
}

.App {
  text-align: center;
}

:root {
  --primary: rgb(255, 255, 255);
  --secondary: rgb(218, 218, 218);
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 15px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 7px;
  border: 3px solid var(--primary);
}